import { Component, AfterViewInit, OnInit,Input } from '@angular/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { HtmlRoutePages } from '../../../../../src/app/app-routing.model';
import {regionEnum} from '../../../core/declaration/declaration'
import { AssetService } from '~/core/services/assets/asset.service';
@Component({
  selector: 'volvo-home-media',
  templateUrl: './home-media.component.html',
  styleUrls: ['./home-media.component.scss'],
  providers: [NgbCarouselConfig]
})
export class HomeMediaComponent implements AfterViewInit {
  HtmlRoutePages = HtmlRoutePages;
   isYouTubeLoaded:boolean[] = [false,false];
 //@Input() isYouTubeLoaded: boolean;
  @Input() twitterLoaded: boolean;
  @Input() region:string;
  @Input() showVideo: boolean;
  regionEnum=regionEnum;
  constructor(config: NgbCarouselConfig, public assetService: AssetService) {
    config.interval = 2000;
    config.keyboard = true;
    config.pauseOnHover = true;
  }
  videoObject = [
    {
      video: 'https://www.youtube.com/embed/uhOU9pJrRhU',
      alt: 'youtube video',
      text:'pages/home/youtube/text1',
    },
  ];
  imageObject = [
    {
      image: '/assets/pages/home/FH460 A0_003.webp',
      imagePath: 'pdf',
      path:'https://www.volvotrucks.co.uk/en-gb/news/press-releases/2024/volvo-used-trucks-extends-volvo-approved-waraanty-offers.html',
      altText: 'pages/home/banner1/alttext'
    },
    {
      image: '/assets/pages/home/Volvofleet.webp',
      imagePath: 'pdf',
      path:'https://www.volvotrucks.co.uk/en-gb/news/press-releases/2023/nov/new-volvo-fleet-deploys-on-training-mission-with-veterans-into-logistics.html',
      altText: 'pages/home/banner2/alttext'
    },
    {
      image: '/assets/pages/home/Grill2_1444126544.webp',
      imagePath: ''
    },
    {
      image: '/assets/pages/home/VUT Selected Web Banner.webp',
      imagePath: 'selected',
      altText: 'pages/home/vehicleoffer/selected/alttext'
    },
    {
      image: '/assets/pages/home/VUT Approved Web Banner.webp',
      imagePath: 'approved',
      altText: 'pages/home/vehicleoffer/approved/alttext'
    },
    {
      image: '/assets/pages/home/VUT Economy Web Banner.webp',
      imagePath: 'economy',
      altText: 'pages/home/vehicleoffer/economy/alttext'
    },
    {
      image: '/assets/pages/home/CMO Banner.webp',
      imagePath: 'pdf',
      path:'assets/files/CMO.pdf',
      altText: 'pages/home/banner3/alttext'
    }
  ];
  ngAfterViewInit(): void {
    // // @ts-ignore
    // (<any>window).twttr.widgets.load();
    // this.twitterLoaded = true;
  }

  ngOnInit():void{
  }

  isIframeLoaded():void{
    var iframes  = document.getElementsByTagName('iframe');
    for (var i = 0; i < iframes.length; ++i) {
      if (iframes[i]) {
        var src = iframes[i].getAttribute('src');
        if (src) {
          if (src.indexOf('youtube.com/embed') !== -1) 
            this.isYouTubeLoaded[i]=false;
          else
            this.isYouTubeLoaded[i]=true;
        }
      }
    }
  }
}
