<section class="volvo">
  <div class="volvo-volvoDiv">
    <div class="volvo-imgDiv">
      <img
        src="assets/pages/buyersGuide/volvoFMX.webp"
        class="volvo-imgDiv-volvoImg" alt=""
      />
    </div>
    <div class="volvo-title">
      <span>{{ "uk/pages/buyersGuide/volvoFMX" | lowercase | translate }}</span>
    </div>
    <div class="volvo-text">
      <span>{{ "uk/pages/buyersGuide/volvoFMX/text1" | lowercase | translate }}</span>
    </div>
    <div class="volvo-text">
      <span>
        {{ "uk/pages/buyersGuide/volvoFMX/text2" | lowercase | translate }}
      </span>
    </div>
    <div class="volvo-text">
      <span>
        {{ "uk/pages/buyersGuide/volvoFMX/text3" | lowercase | translate }}
      </span>
    </div>
    <div class="volvo-text">
      <span class="volvo-text-medium">{{
        "uk/pages/buyersGuide/volvoFMX/text4" | lowercase | translate
      }}</span
      >{{ "uk/pages/buyersGuide/volvoFMX/text5" | lowercase | translate }}
    </div>
  </div>
</section>
<volvo-footer></volvo-footer>
