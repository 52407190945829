<section class="volvo">
  <div class="volvo-volvoDiv">
    <div class="volvo-imgDiv">
      <img
        src="assets/pages/buyersGuide/volvoFH16.webp"
        class="volvo-imgDiv-volvoImg" alt=""
      />
    </div>
    <div class="volvo-title">
      <span>{{ "uk/pages/buyersGuide/volvoFH16" | lowercase | translate }}</span>
    </div>
    <div class="volvo-text">
      <span>{{ "uk/pages/buyersGuide/volvoFH16/text1" | lowercase | translate }}</span>
    </div>
    <div class="volvo-text">
      <span class="volvo-text-medium">{{
        "uk/pages/buyersGuide/volvoFH16/text2" | lowercase | translate
      }}</span
      >{{ "uk/pages/buyersGuide/volvoFH16/text3" | lowercase | translate }}
    </div>
    <div class="volvo-text">
      <span class="volvo-text-medium">{{
        "uk/pages/buyersGuide/volvoFH16/text4" | lowercase | translate
      }}</span
      >{{ "uk/pages/buyersGuide/volvoFH16/text5" | lowercase | translate }}
    </div>
  </div>
</section>
<volvo-footer></volvo-footer>
